import React from "react"
import Img from "gatsby-image"

const Thumbnail = ({logo, alt}) => (
  <div style={{height:50, width: 50, display:'flex', alignItems:'center', justifyContent:'center', backgroundColor:'none'}}>
    { (logo && (logo.childImageSharp || logo.url)) &&
      (
        logo.extension !== "svg" && logo.childImageSharp ?
        <Img fixed={logo.childImageSharp.fixed} alt={alt}/> :
        <img src={logo.url} alt={alt} style={{width:"100%", height:"100%"}} />
        
      )
    }
    {
      (!logo || (!logo.childImageSharp && !logo.url)) &&
      <p>{alt}</p> 
    }
  </div>  
)

export default Thumbnail
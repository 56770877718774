import { useState, useEffect } from 'react';
import { useAuth } from "gatsby-theme-firebase";
import Airtable from "airtable"
import CleanAndMapSessions from "../utils/CleanAndMapSessions"

Airtable.configure({ apiKey: process.env.GATSBY_AIRTABLE_API_KEY })
const base = Airtable.base(process.env.GATSBY_AIRTABLE_BASE_ID)

export const useAirtableSessions = (companyId, reFetchSessions) => {
  const { profile } = useAuth();
  //const [sessions, setSessions] = useState([])
  const [companySessionMap, setCompanySessionMap] = useState({})
  const [error, setError] = useState(null)

  const fetchSessions = async () => {
    const searchConfig = {
      view: "API"
    }
    if(companyId) {
      searchConfig["filterByFormula"] = `OR({Company ID}="${companyId}",{Company Lookup via Member}="${companyId}")`
    }
    const results = []
    await base('Engagements').select(searchConfig).eachPage((records, fetchNextPage) => {
      records.forEach(record => {
        const newRecord = record.fields
        newRecord['id'] = record.id
        var date
        if(typeof newRecord['Date Formula'] === "object") {
          if(newRecord['Date Formula'].length === 1) {
            date = newRecord['Date Formula'][0]
          }
        } else {
          date = newRecord['Date Formula']
        }
        newRecord['Date Formula'] = date
        delete Object.assign(newRecord, {["Member_Name"]: newRecord["Member Name"] })["Member Name"]
        delete Object.assign(newRecord, {["Attended_"]: newRecord["Attended?"] })["Attended?"]
        results.push(newRecord)
      })
      fetchNextPage()
    })
    return results
  }

  useEffect(() => {
    if(reFetchSessions) {
      console.log('fetching sessions')
      const sessionRequest = fetchSessions()
      sessionRequest.then((results) => {
        setCompanySessionMap(CleanAndMapSessions(results))
      })
      .catch((err) => {
        console.log(err);
        setError('Error fetching sessions');
      })
    }
  }, [reFetchSessions])
  if(error) {
    return error
  } else {
    return companySessionMap
  }
}

// gets an airtable staff row based on email
export const useAirtableUser = () => {
  const { profile } = useAuth();
  const [userId, setUserId] = useState(null)
  const [error, setError] = useState(null)

  const fetchCurrentUser = async (email) => {
    return await base('Staff').select({
      maxRecords: 1,
      view: "API",
      filterByFormula: `{Email}="${email}"`
    }).firstPage((err, records) => {
      if(!err) {
        if(records.length === 1) {
          setUserId(records[0].id)
        } else {
          setError('No user found in Airtable')
        }
      } else {
        setError('Error retrieving user')
      }
    })
  }

  useEffect(() => {
    try {
      profile && profile.email &&
      fetchCurrentUser(profile.email)
    }
    catch(err) {
      console.log("Error fetching Airtable user", err)
      setError('Error retrieving user')
    }
  }, [profile])
  
  if(error) {
    return error
  }
  else {
    return userId
  }
}
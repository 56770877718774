import React, {useState, useEffect} from "react"
import ReactMarkdown from "react-markdown"
import { css } from "@emotion/core"
import Colors from "../components/colors"
import Airtable from "airtable"

const Milestone = ({milestone, condensed, width}) => {
  const [expanded, setExpanded] = useState(false)
  const [progress, setProgress] = useState(milestone.fields.Actual)
  const [tempProgress, setTempProgress] = useState(progress)
  const [submitUpdate, setSubmitUpdate] = useState(false)
  const [milestoneError, setMilestoneError] = useState(null)

  const pushAirtableUpdate = async (id, actual) => {
    var base = new Airtable({apiKey: process.env.GATSBY_AIRTABLE_API_KEY}).base(process.env.GATSBY_AIRTABLE_BASE_ID);
    return await base('Milestones').update([
      {
        id: id,
        fields: {
          Actual: parseInt(actual)
        }
      }
    ])
  }
  useEffect(() => {
    
    if(submitUpdate) {
      pushAirtableUpdate(milestone.id, tempProgress).then((result) => {
        if(!result || result.length !== 1) {
          setMilestoneError('error!')
        }
        else {
          setProgress(result[0].get('Actual'))
          setMilestoneError(null)
          setExpanded(false)
        }
        setSubmitUpdate(false)
      }).catch((err) => {
        console.log('caught error', err)
        setMilestoneError(err.message)
        setSubmitUpdate(false)
      })
    }
  }, [submitUpdate])

  const record = milestone.fields
  const proportion = progress / record.Goal
  var bg = 'grey'
  if(proportion > 0.7) {
    bg = Colors.green[0]
  } else if(proportion > 0.4) {
    bg = Colors.yellow[0]
  } else {
    bg = Colors.red[0]
  }
  const widthPx = width ? width : 150;
  const barWidth = proportion * widthPx
  return(
    <div style={{margin:'0 0.5rem 0 0', width:widthPx,}}>
      <div style={{fontSize:'0.75rem', fontWeight:'bold'}}>{record.Quarter && record.Quarter} {record.Year}</div>
      <div style={{fontSize:'0.75rem', textOverflow:'ellipsis', whiteSpace:'nowrap', overflow:'hidden'}} label={record.Name}>{record.Name }{record.Notes ? "*" : ""}</div>
      <button
        label="click to edit milestone progress"
        css={css`
          width: 100%;
          background-color: ${Colors.gray[0]};
          border: 0.5px solid ${Colors.gray[0]};
          height: 30px;
          position: relative;
          padding: 0px;
          cursor: pointer;
          
        `}
        onClick={(e) => {
          e.preventDefault()
          e.stopPropagation()
          setExpanded(!expanded)
        }}
      >
        <div style={{height:28, width:barWidth, backgroundColor: bg }} />
        <span style={{fontSize:'0.75rem', paddingLeft:'0.5rem', left:0, top:0, position:'absolute'}}>
          {progress}/{record.Goal}
        </span>
      </button>
      <div style={{display: milestoneError ? 'block' : 'none'}}>{milestoneError}</div>
      <div style={{display: expanded ? 'block' : 'none'}}>
        <label htmlFor="progress">
            <input
              id="progress"
              type="number"
              name="progress"
              value={tempProgress}
              onChange={e => setTempProgress(e.target.value)}
            />
            <button htmlFor="progress" onClick={(e) => {
              e.preventDefault()
              e.stopPropagation()
              setSubmitUpdate(true)
            }}>Update</button>
        </label>

      </div>
      {
        !condensed && record.Notes &&
        <div>
          <div style={{display: expanded ? 'block' : 'none',}}>
            <ReactMarkdown source={record.Notes}/>
          </div>
        </div>
      }
    </div>
  )
}

export default Milestone

import React, {useState, useEffect} from "react"
import { css } from "@emotion/core"
import { Link, navigate } from "gatsby"
import Thumbnail from "./Thumbnail"
import TextItem from "./TextItem"
import ReactMarkdown from "react-markdown"
import Milestone from "./Milestone"
import Engagement from "./Engagement"
import Colors from "./colors"
import AirtableLink from "./AirtableLink"


const CompanyListItem = ({company, headerMode, sessions}) => {
  const logo = company.Logo ? company.Logo.localFiles[0] : null;
  const [expanded, setExpanded] = useState(false);
  const [latestSession, setLatestSession] = useState(null)

  useEffect(() => {
    if(sessions && !latestSession) {
      setLatestSession(sessions.filter((s) => s.Type === "Coaching" ? true : false).sort((a,b) => a.Date > b.Date ? -1 : 1)[0])
    }
  }, [sessions])

  const navigateToCompany = () => navigate(`/companies/${company.Id}`)
  return (
    <div 
      onClick={()=> navigateToCompany()}
      onKeyDown={(e) => {
        // 32=space, 13=enter
        if(e.keyCode === 32 || e.keyCode === 13) {
          navigateToCompany()
        }
      }}
      tabIndex="0"
      aria-label={`Clickable card for ${company.api_company_name}`}
      role="button"
      css={css`
        color: inherit;
        border: 0;
        width: 100%;
        background: transparent;
        flex: 1;
        text-decoration: none;
        max-width: 700px;
        display:flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: stretch;;
        text-align: left;
        box-shadow: ${headerMode ? 'none' : "rgba(0, 0, 0, 0.2) -1px 3px 6px 0px"};
        background-color: ${Colors.white};
        text-decoration: none;
        margin: 1rem;
        padding: 0.5rem 1.5rem;
        min-height: 200px;
        min-width: 300px;
        flex-wrap: wrap;
        color: ${Colors.black};
        transition: box-shadow 0.25s linear;
        &:hover {
          box-shadow: ${headerMode ? 'none' : "rgba(0, 0, 0, 0.2) -1px 6px 12px 5px"};
        }
        &:visited {
          color: ${Colors.black};
        }
      `}>
        <div css={css`
          width: 100%;
          display: flex;
          flex-wrap: wrap;
          align-items: flex-end;
          justify-content: space-between;
        `}>
          <div css={css`
            color: inherit;
            text-decoration: none;
            display: flex;
            align-items: center;
          `}>
            <Thumbnail logo={logo} alt={company.api_company_name}/>
            <div css={css`height: 100%; margin: 0 1rem; font-weight: bold;`}>{company.api_company_name}</div>
          </div>
          <div style={{display:"flex", flexWrap:'wrap', margin:'0 -0.5rem'}}>
            <TextItem title="Account Mgr" value={company.AM_Name ? company.AM_Name[0] : ""} />
            <TextItem title="# Mems" value={company.Active_Members} align="center" />
            <TextItem title="Coach Hrs" value={Math.round(company.Adjusted_Support_Hours)} align="center" />
          </div>
        </div>
      
        <div style={{ minHeight:125, display:"flex", flexDirection: "column", justifyContent:'center', alignItems: headerMode ? 'center' : 'flex-start',}}>
            <Engagement sessions={sessions} companyId={company.Id}/>
        {
          !headerMode &&
          <div style={{display: 'flex', alignItems:'flex-end', justifyContent:'space-between', width:'100%', alignSelf: 'flex-end'}}>
            {
              latestSession &&
              !latestSession.error ?
              <div style={{overflow:'hidden', flex:1}}>
                <div style={{fontSize:12, lineHeight:'0.75rem'}}>Latest Coaching Session ({latestSession.Date}):</div>
                <div>
                  <div style={{margin:0, fontSize:16}}>
                    {latestSession.Title}
                    {
                      latestSession.Summary &&
                        <button
                          css={css`
                            margin-left: 1rem;
                            text-decoration: none;
                            color: blue;
                            font-weight: normal;
                            font-size: 12px;
                            cursor: pointer;
                            border: 0;
                            background-color: transparent;
                            &:hover {
                              font-weight: bold;
                            }
                          `}
                          onClick={(e) => {
                            setExpanded(!expanded)
                            e.preventDefault()
                            e.stopPropagation()
                          }}
                        >
                          {expanded ? 'collapse' : 'expand'}
                        </button>
                      }
                  </div>
                  {
                    latestSession.Summary &&
                    <div style={{display: expanded ? 'block' : 'none', maxWidth:500}}>
                      <ReactMarkdown source={latestSession.Summary}/>
                    </div>
                  }
                </div>
              </div> :
              <div style={{flex:1}}>{latestSession && latestSession.error}</div> 
            }
            <AirtableLink tableId="tblZlEm3muuaTnigY" viewId="viweafKUDysSxmoS4" recordId={company.Id} />
          </div>
        }
      </div>
    </div>
  )
}

export default CompanyListItem

import React from "react"
//import PropTypes from "prop-types"
import { Sparklines, SparklinesLine, SparklinesText } from 'react-sparklines'
import { addWeeks, isWithinInterval, eachWeekOfInterval, startOfWeek, format } from 'date-fns'


const Engagement = ({companyId, sessions}) => {

    const dateInterval = {start:addWeeks(new Date(), -13), end:addWeeks(new Date(), -1)}

    // takes an array of objects where one key is a date (string) and one key is a value to be summed
    // groups the objects into the weeks within interval
    const sumByWeek = (objects, interval, dateKey, valueKey) => {
        const initialValue = {}
        const weeks = eachWeekOfInterval(interval).map(week => format(week, 'yyyy-MM-dd'))
        weeks.forEach(week => {
            initialValue[week] = 0
        })
        const weekMap = objects.reduce((accumulator, currentValue) => {
            const objectDate = new Date(currentValue[dateKey])
            if(isWithinInterval(objectDate, interval)) {
                const week = format(startOfWeek(objectDate), 'yyyy-MM-dd')
                if(currentValue[valueKey]) {
                    accumulator[week] += currentValue[valueKey]
                }
            }
            return accumulator
        }, initialValue)
        return weeks.map(key => weekMap[key])
    }

    const getCoachingData = (sessions) => {
        if(sessions) {
            const coaching = sessions.filter((s) => s.Type === "Coaching" ? true : false)
            return sumByWeek(coaching, dateInterval, "Date Formula", "Minutes")
        }
        else {
            console.log('something went wrong trying to get coaching data', sessions)
        }
    }

    const getEventData = (sessions) => {
        if(sessions) {
            const events = sessions.filter((s) => s.Type === "Event" ? true : false)
            const result = sumByWeek(events, dateInterval, "Date Formula", "Attended_")
            return result
        }
        else {
            console.log('something went wrong trying to get events data', sessions)
        }
    }

    const getMaxValueFromObjects = (objects, key) => {
        var currentMax = 0
        objects.forEach(obj => {
            if(obj[key] > currentMax) {
                currentMax = obj[key]
            }
        })
        return currentMax
    }
    
    // gets random data for now
    const getData = () => {
        if(sessions) {
            return [
                {label: "Coaching (12wks, minutes)", data: getCoachingData(sessions), max: 400, color: "blue"},
                {label: "Events", data: getEventData(sessions), max:5, color: "blue"},
                {label: "Space", data: [], max:100, color: "grey"}
            ]
        } else {
            return [
                {label: "Coaching", data: [], color: "blue"},
                {label: "Events", data: [], color: "blue"},
                {label: "Space", data: [], color: "blue"}
            ]
        }
    }

    const getColor = (data) => {
        /*const len = data.length
        if(len > 1) {
            if(data[len-1] > data[len-2]) {
                return "green"
            }
            else if(data[len-1] < data[len-2]) {
                return "red"
            }
        }*/
        return "blue"
    }
    return (
        <div style={{display:"flex", flexWrap:'wrap', width:'100%'}}>
        {
            getData().map(params => (
                <div key={params.label} style={{flex:1, margin:10}}>
                    <div style={{fontSize:10}}>{params.label}</div>
                    <Sparklines data={params.data} min={0} max={params.max}>
                        <SparklinesLine color={params.color} />
                        <SparklinesText text={params && params.max && params.max.toString()} point={{x:0,y:10}}/>
                        <SparklinesText text="0" point={{x:0,y:"100%"}}/>
                    </Sparklines>
                </div>
            ))
        }
        </div>
    )
}
    
/*
const SparkParams = PropTypes.shape({
    data: PropTypes.arrayOf(PropTypes.number).isRequired,
    label: PropTypes.string.isRequired,
})


Engagement.propTypes = {
    sparklines: PropTypes.arrayOf(SparkParams).isRequired,
}
*/

export default Engagement